<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="战略机会点分解" name="1" v-if="permissionControl('OpportunityPointDecomposition')">
				<opportunities ref="strategicDecoding1" v-if="activeName === '1'" pageName="OpportunityPointDecomposition" />
			</el-tab-pane>
			<el-tab-pane label="实现路径分解" name="2" v-if="permissionControl('ImplementPathDecomposition')">
				<opportunities ref="strategicDecoding2" v-if="activeName === '2'" pageName="ImplementPathDecomposition" />
			</el-tab-pane>
			<el-tab-pane label="关键任务分解" name="3" v-if="permissionControl('KeyTaskDecomposition')">
				<opportunities ref="strategicDecoding3" v-if="activeName === '3'" pageName="KeyTaskDecomposition" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import opportunities from './opportunities.vue';

// import result from './result.vue';
export default {
	name: 'strategicDecoding',
	components: { opportunities },

	data() {
		return { activeName: '' };
	},
	watch: {
		activeName() {
			console.log(this.activeName, 'activeName');
		}
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('strategicDecodingData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'OpportunityPointDecomposition' },
			{ name: '2', key: 'ImplementPathDecomposition' },
			{ name: '3', key: 'KeyTaskDecomposition' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'strategicDecodingData',
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`strategicDecoding${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>
